.truncate {
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Card {
    /* margin-left: auto;
    margin-right: auto; */
    min-height: 64px;
    background-color: var(--workspace-column-card-color);
    margin-bottom: 4px;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.25);
    /* width: 220px; */
}
/* 
.Card {
    margin: 4px 0;
} */


.card-container {
    width: 280px;
    box-sizing: content-box;
    /* border: 1px solid black; */
    /* border-radius: 5px; */
    /* padding: 0 5px; */
}

.card-extra-details {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #e0f0f9;
}


.HeaderBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    padding: 6px 8px;
}

.Link {
    color: #29a9e2;
    font-size: 16px;
    font-weight: bold;
}

.RepoName {
    flex: 1;
    font-size: 16px;
    color: #6990a1;
    margin-left: 8px;
}



.AssigneeContainer {
    margin-left: 8px;
}

.Assignee:not(:first-child) {
    margin-left: 2px;
}

.Assignee {
    width: 16px;
    height: 16px;
    box-sizing: content-box;
    border-radius: 2px;
}

.OverflowAssignee {
    margin-left: 2px;
    width: 16px;
    height: 16px;
    font-size: 10px;
    text-align: center;
    line-height: 16px;
    border-radius: 2px;
    background-color: #d8d8d8;
}

.CardBody {
    padding: 4px 8px 12px;
    word-break: break-word;
    color: var(--card-body-color);
    font-size: 16px;
    font-weight: 500;
}

.Footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 10px;
    margin-right: 8px;
    /* margin-bottom: 4px; */
}

.spacer-4 {
    width: 4px;
}

.milestone-details {
    color: #6990a1;
    font-size: 16px;
    margin-left: 10px;
    padding-bottom: 8px;
}

.milestone-details a {
    color: inherit !important;
}

.LabelCard:last-child {
    margin-bottom: 8px;
}


.LabelText {
    margin: 0;
    line-height: 20px;
    font-size: 12px;
    /* display: none; */
}

.LabelCard {
    padding: 0 8px;
    display: block;
    border-radius: 2px;
    margin-right: 4px;
    margin-bottom: 4px;
    height: 20px;
    max-width: 100%;
    border-radius: 5px;
    /* transition: max-width 0.2s; */
    box-sizing: border-box;

}

@media screen and (max-width: 768px) {
    .Card {
        width: 100%;
    }

    .card-container {
        width: 100%;
    }

}


/* .Card:hover .LabelCard {
    max-width: 100%;
    border-radius: 5px;
    transition: max-width 0.2s;
} */

/* .Card:hover .LabelText {
    display: block;
} */