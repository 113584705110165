
.app-nav-bar-2 {
    height: 48px;
    background-color: var(--app-user-repos-background-color);
    width: 288px;
    color: var(--primary-icon-color);
}


.repo-title {
    font-size: 13px;
    font-weight: 500;
}

.repo-title-container {
    height: 24px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    cursor: pointer;
}

.repo-title-container[selected] {
    background: rebeccapurple;
}

.repo-title-container:hover {
    background: #29a9e2;
}

.repo-title-container:hover .repo-title {
    font-weight: bold;
}

@media screen and (max-width: 768px) {
    .app-nav-bar-2 {
        width: 100%;
    }
}
