.filter-icon-container {
    width: 48px;
    height: 48px;
}

 .filter-icon:hover {
    cursor: pointer;
}

.filter-container {
    /* height: 324px; */
    min-height: 380px;
    max-height: 500px;
}

.filter-body {
    background-color: rgba(0,0,0,.03);
}

 .filter-header {
    min-width: 340px;
    width: 100%;
    height: 48px;
    background-color: var(--app-user-repos-background-color);
    padding: 0 8px 0 16px;
    color: var(--secondary-text-color);
    font-weight: bold;
}

 .filter-menu-expansion-panel[expanded] .filter-down-arrow {
    display: none;
}

 .fitler-menu-detail {
    background-color: #eee;
}

 .filter-menu-title-container {
    font-weight: bold;
    color: rgba(0,0,0,0.87);
}

 .filter-menu-title {
    margin-left: 32px;
}

.rotate-90 {
    transform: rotate(90deg);
}

.chevron {
    width: 24px;
    height: 24px;
}


@media screen and (max-width: 768px) {
    .filter-header {
        min-width: unset;
    }

    .filter-popover {
        width: 100% !important;
        height: 100% !important;
        max-width: 100% !important;
        max-height: 100% !important;
        top: 0 !important;
        left: 0 !important;
        background-color: white !important;
    }

    .filter-container {
        min-height: 100%;
        max-height: 100%;
    }

}
