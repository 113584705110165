.right-panel {
    width: 588px;
    overflow-x: hidden;
}

#rightPanel:first-child {
    width: 588px;
    overflow-x: hidden;
}

/* 
div[role="presentation"] {
    width: 588px;
} */

/* div[role="document"] .app-nav-bar-2 {
    width: inherit;
    top: 48px;
    height: calc(100% - 48px);
    color: white;
} */

.panel-container {
    padding: 16px;
}

@media screen and (max-width: 768px) {
    .right-panel {
        width: 100%;
    }
    #rightPanel:first-child {
        width: 100%;
    }

    div[role="document"] {
        width: 100%;
    }
}

