/* TODO: Setup mixins here */

*, *:before, *:after {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
  -webkit-overflow-scrolling: touch;
}


::-webkit-scrollbar-track {
    background-color: transparent;
}
::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(35, 35, 35, 1);
}
::-webkit-scrollbar {
    background-color: transparent;
    width: 2px;
    height: 10px;
}

html {
      --app-user-repos-background-color: "#176080";
      --app-workspace-background-color: "#e1f1f8";
      --primary-icon-color: "white";
      --secondary-icon-color: "rgba(0, 0, 0, 0.8)";
      --workspace-column-card-color: "#f2f6f8";
      --primary-text-color: "#176080";

    /* Default styling */
    color: var(--primary-text-color);

}

/* .app-logo {
  animation: app-logo-spin infinite 8s linear;
  height: 64px;
  pointer-events: none;
} */

.app-background {
    background-color: #e1f1f8;
}

.krum-link {
    color: var(--primary-text-color) !important;
}

.krum-link:hover {
    text-decoration: underline !important;
}


@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.header-component-container {
    background-color: var(--app-user-repos-background-color);
    border-bottom: 1px solid black;
    color: var(--secondary-text-color);
}

.app-side-bar-condensed {
    width: 48px;
    background-color: var(--app-side-bar-condensed-background-color);
    box-shadow: 0 0px 10px rgba(0,0,0,0.19);

}

.app-header {
    height: 48px;
    background-color: var(--app-user-repos-background-color);
    color: var(--primary-icon-color);
}


.app-home-container:last-child {
    height: 100%;
}

.add-cards-container {
    max-width: 860px;
    margin-left: auto;
    margin-right: auto;
    /* border-right: 1px solid rgb(54, 5, 5);
    border-left: 1px solid rgb(54, 5, 5); */
    padding: 16px;
    /* background-color: var(--app-workspace-background-color); */
    /* background-color: white; */
}

.settings-container {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding: 16px;
}

.settings-item-container {
    height: 48px;
    padding-left: 16px;
    cursor: pointer;
    /* border-bottom: 1px solid black; */
}

.settings-item-container > * {
    pointer-events: none;
}

.selected-repos-container {
    padding: 4px 8px;
    border: 1px solid var(--app-user-repos-background-color);
    margin: 4px;
    border-radius: 4px;
}

.selected-repos-add-container {
    padding: 4px 8px;
    background-color: var(--app-user-repos-background-color);
    margin: 4px;
    border-radius: 4px;
    color: white;
}

.hover-div {
    height: 60%;
    width: 2px;
    background-color: var(--app-user-repos-background-color);
    margin-left: 1px;
    display: none;
} 

 .settings-item-container[active='true'] .hover-div {
    display: block;
}

.settings-item-container[active='true'] span {
    font-weight: bold;
}

.add-cards-panel:first-child {
    width: 100%;
}

.login-subtext {
    font-size: 12px;
    letter-spacing: 0.2px;
}

.txt-bold {
    font-weight: bold;
}

.icon {
    color: var(--primary-icon-color);
    width: 24px;
    height: 24px;
}


.icon-2 {
    color: var(--secondary-icon-color);
    width: 24px;
    height: 24px;
}

.opacity-54 {
    opacity: 0.54;
}

.truncate {
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
}

.full-height {
    height: 100%;
}

.height-10 {
    height: 10px;
}

.height-20 {
    height: 20px;
}
.text-center {
    text-align: center;
}

.form-shell {
    padding: 10px;
}

.spacer-8 {
    width: 8px
}

.vertical-center {
    align-items: center;
    justify-content: center;
}

.container-overflow-y {
    overflow-y: auto;
    overflow-x: hidden;
}


.profile-image-container {
    width: 48px;
    height: 48px;
}

.profile-image-container img {
    width: 24px;
    height: 24px;
    border-radius: 4px; 
}

.react-swipeable-view-container {
    height: 100%;;
}

.form-margin {
    margin: 0 10px;
}

.margin-16 {
    margin: 0 16px;
}

.cursor-pointer {
    cursor: pointer;
}

.txt-hover:hover {
    text-decoration: underline;
}

.container-56 {
    height: 56px;
}

#appHome {
    height: 100vh;
}



.title-container {
    margin-left: 16px;
}

.padding-8 {
    padding: 8px;
}



/* Loader */
/* Referenced from https://codepen.io/ikoshowa/pen/MaqodQ */

.loader {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #03a9f4;
    border-radius: 50%;
    height: 50px;
    width: 50px;
}
 .loader:after {
    content: '';
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    border: 0px solid white;
    transform: translate(-50%, -50%);
    animation: loading 1000ms ease-out forwards infinite;
}
 
@keyframes loading {
  0% {
    border: 0px solid white;
  }
  
  20% {
    border: 8px solid white;
    width: 0%;
    height: 0%;
  }
  
  100% {
    border: 8px solid white;
    width: 100%;
    height: 100%;
  }
}

/* Global tag styling */

a {
    text-decoration: none !important;
    color: inherit !important;
}

button > span > a {
    display: block;
    height: 100%;
    width: 100%;
    color: inherit !important;
}


/* Dialog styling */

.dialog-container {
    width: 400px;
    height: 400px;
}

.dialog-container-override {
    width: 320px;
    height: 460px;
}

.dialog-shell {
    margin: 0 !important;
    max-height: 100% !important;

}

@media screen and (max-width: 768px) {
    .dialog-container {
        width: 100vw;
        height: 100vh;
        max-height: 100% !important;
        margin: 0 !important;
    }

    .dialog-container-override {
        width: 100vw;
        height: 100vh;
        max-height: 100% !important;
        margin: 0 !important;
    }
}

