/* TODO: Setup mixins here */

*, *:before, *:after { /* Safari/Chrome, other WebKit */    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
  -webkit-overflow-scrolling: touch;
}


::-webkit-scrollbar-track {
    background-color: transparent;
}
::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(35, 35, 35, 1);
}
::-webkit-scrollbar {
    background-color: transparent;
    width: 2px;
    height: 10px;
}

html {
      --app-user-repos-background-color: "#176080";
      --app-workspace-background-color: "#e1f1f8";
      --primary-icon-color: "white";
      --secondary-icon-color: "rgba(0, 0, 0, 0.8)";
      --workspace-column-card-color: "#f2f6f8";
      --primary-text-color: "#176080";

    /* Default styling */
    color: "#176080";
    color: var(--primary-text-color);

}

/* .app-logo {
  animation: app-logo-spin infinite 8s linear;
  height: 64px;
  pointer-events: none;
} */

.app-background {
    background-color: #e1f1f8;
}

.krum-link {
    color: "#176080" !important;
    color: var(--primary-text-color) !important;
}

.krum-link:hover {
    text-decoration: underline !important;
}


@-webkit-keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.header-component-container {
    background-color: "#176080";
    background-color: var(--app-user-repos-background-color);
    border-bottom: 1px solid black;
    color: var(--secondary-text-color);
}

.app-side-bar-condensed {
    width: 48px;
    background-color: var(--app-side-bar-condensed-background-color);
    box-shadow: 0 0px 10px rgba(0,0,0,0.19);

}

.app-header {
    height: 48px;
    background-color: "#176080";
    background-color: var(--app-user-repos-background-color);
    color: "white";
    color: var(--primary-icon-color);
}


.app-home-container:last-child {
    height: 100%;
}

.add-cards-container {
    max-width: 860px;
    margin-left: auto;
    margin-right: auto;
    /* border-right: 1px solid rgb(54, 5, 5);
    border-left: 1px solid rgb(54, 5, 5); */
    padding: 16px;
    /* background-color: var(--app-workspace-background-color); */
    /* background-color: white; */
}

.settings-container {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding: 16px;
}

.settings-item-container {
    height: 48px;
    padding-left: 16px;
    cursor: pointer;
    /* border-bottom: 1px solid black; */
}

.settings-item-container > * {
    pointer-events: none;
}

.selected-repos-container {
    padding: 4px 8px;
    border: 1px solid "#176080";
    border: 1px solid var(--app-user-repos-background-color);
    margin: 4px;
    border-radius: 4px;
}

.selected-repos-add-container {
    padding: 4px 8px;
    background-color: "#176080";
    background-color: var(--app-user-repos-background-color);
    margin: 4px;
    border-radius: 4px;
    color: white;
}

.hover-div {
    height: 60%;
    width: 2px;
    background-color: "#176080";
    background-color: var(--app-user-repos-background-color);
    margin-left: 1px;
    display: none;
} 

 .settings-item-container[active='true'] .hover-div {
    display: block;
}

.settings-item-container[active='true'] span {
    font-weight: bold;
}

.add-cards-panel:first-child {
    width: 100%;
}

.login-subtext {
    font-size: 12px;
    letter-spacing: 0.2px;
}

.txt-bold {
    font-weight: bold;
}

.icon {
    color: "white";
    color: var(--primary-icon-color);
    width: 24px;
    height: 24px;
}


.icon-2 {
    color: "rgba(0, 0, 0, 0.8)";
    color: var(--secondary-icon-color);
    width: 24px;
    height: 24px;
}

.opacity-54 {
    opacity: 0.54;
}

.truncate {
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
}

.full-height {
    height: 100%;
}

.height-10 {
    height: 10px;
}

.height-20 {
    height: 20px;
}
.text-center {
    text-align: center;
}

.form-shell {
    padding: 10px;
}

.spacer-8 {
    width: 8px
}

.vertical-center {
    align-items: center;
    justify-content: center;
}

.container-overflow-y {
    overflow-y: auto;
    overflow-x: hidden;
}


.profile-image-container {
    width: 48px;
    height: 48px;
}

.profile-image-container img {
    width: 24px;
    height: 24px;
    border-radius: 4px; 
}

.react-swipeable-view-container {
    height: 100%;;
}

.form-margin {
    margin: 0 10px;
}

.margin-16 {
    margin: 0 16px;
}

.cursor-pointer {
    cursor: pointer;
}

.txt-hover:hover {
    text-decoration: underline;
}

.container-56 {
    height: 56px;
}

#appHome {
    height: 100vh;
}



.title-container {
    margin-left: 16px;
}

.padding-8 {
    padding: 8px;
}



/* Loader */
/* Referenced from https://codepen.io/ikoshowa/pen/MaqodQ */

.loader {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #03a9f4;
    border-radius: 50%;
    height: 50px;
    width: 50px;
}
 .loader:after {
    content: '';
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    border: 0px solid white;
    transform: translate(-50%, -50%);
    -webkit-animation: loading 1000ms ease-out forwards infinite;
            animation: loading 1000ms ease-out forwards infinite;
}
 
@-webkit-keyframes loading {
  0% {
    border: 0px solid white;
  }
  
  20% {
    border: 8px solid white;
    width: 0%;
    height: 0%;
  }
  
  100% {
    border: 8px solid white;
    width: 100%;
    height: 100%;
  }
}
 
@keyframes loading {
  0% {
    border: 0px solid white;
  }
  
  20% {
    border: 8px solid white;
    width: 0%;
    height: 0%;
  }
  
  100% {
    border: 8px solid white;
    width: 100%;
    height: 100%;
  }
}

/* Global tag styling */

a {
    text-decoration: none !important;
    color: inherit !important;
}

button > span > a {
    display: block;
    height: 100%;
    width: 100%;
    color: inherit !important;
}


/* Dialog styling */

.dialog-container {
    width: 400px;
    height: 400px;
}

.dialog-container-override {
    width: 320px;
    height: 460px;
}

.dialog-shell {
    margin: 0 !important;
    max-height: 100% !important;

}

@media screen and (max-width: 768px) {
    .dialog-container {
        width: 100vw;
        height: 100vh;
        max-height: 100% !important;
        margin: 0 !important;
    }

    .dialog-container-override {
        width: 100vw;
        height: 100vh;
        max-height: 100% !important;
        margin: 0 !important;
    }
}



.app-nav-bar-2 {
    height: 48px;
    background-color: var(--app-user-repos-background-color);
    width: 288px;
    color: var(--primary-icon-color);
}


.repo-title {
    font-size: 13px;
    font-weight: 500;
}

.repo-title-container {
    height: 24px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    cursor: pointer;
}

.repo-title-container[selected] {
    background: rebeccapurple;
}

.repo-title-container:hover {
    background: #29a9e2;
}

.repo-title-container:hover .repo-title {
    font-weight: bold;
}

@media screen and (max-width: 768px) {
    .app-nav-bar-2 {
        width: 100%;
    }
}


/* IE 10 support for HTML5 hidden attr */
[hidden] {
display: none !important;
}
:root {

\--layout: {
    display: flex;
};

\--layout-inline: {
    display: inline-flex;
};

\--layout-horizontal: {
    @apply(--layout);
    flex-direction: row;
};

\--layout-horizontal-reverse: {
    @apply(--layout);
    flex-direction: row-reverse;
};

\--layout-vertical: {
    @apply(--layout);
    flex-direction: column;
};

\--layout-vertical-reverse: {
    @apply(--layout);
    flex-direction: column-reverse;
};

\--layout-wrap: {
    flex-wrap: wrap;
};

\--layout-no-wrap: {
    flex-wrap: nowrap;
};

\--layout-wrap-reverse: {
    flex-wrap: wrap-reverse;
};

\--layout-flex-auto: {
    flex: 1 1 auto;
};

\--layout-flex-none: {
    flex: none;
};

\--layout-flex: {
    flex: 1 1;
    flex-basis: 0.000000001px;
};

\--layout-flex-2: {
    flex: 2 1;
};

\--layout-flex-3: {
    flex: 3 1;
};

\--layout-flex-4: {
    flex: 4 1;
};

\--layout-flex-5: {
    flex: 5 1;
};

\--layout-flex-6: {
    flex: 6 1;
};

\--layout-flex-7: {
    flex: 7 1;
};

\--layout-flex-8: {
    flex: 8 1;
};

\--layout-flex-9: {
    flex: 9 1;
};

\--layout-flex-10: {
    flex: 10 1;
};

\--layout-flex-11: {
    flex: 11 1;
};

\--layout-flex-12: {
    flex: 12 1;
};

/* alignment in cross axis */

\--layout-start: {
    align-items: flex-start;
};

\--layout-center: {
    align-items: center;
};

\--layout-end: {
    align-items: flex-end;
};

\--layout-baseline: {
    align-items: baseline;
};

/* alignment in main axis */

\--layout-start-justified: {
    justify-content: flex-start;
};

\--layout-center-justified: {
    justify-content: center;
};

\--layout-end-justified: {
    justify-content: flex-end;
};

\--layout-around-justified: {
    justify-content: space-around;
};

\--layout-justified: {
    justify-content: space-between;
};

\--layout-center-center: {
    @apply(--layout-center);
    @apply(--layout-center-justified);
};

/* self alignment */

\--layout-self-start: {
    -ms-align-self: flex-start;
    align-self: flex-start;
};

\--layout-self-center: {
    -ms-align-self: center;
    align-self: center;
};

\--layout-self-end: {
    -ms-align-self: flex-end;
    align-self: flex-end;
};

\--layout-self-stretch: {
    -ms-align-self: stretch;
    align-self: stretch;
};

\--layout-self-baseline: {
    -ms-align-self: baseline;
    align-self: baseline;
};

/* multi-line alignment in main axis */

\--layout-start-aligned: {  /* IE10 */
    -ms-align-content: flex-start;
    align-content: flex-start;
};

\--layout-end-aligned: {  /* IE10 */
    -ms-align-content: flex-end;
    align-content: flex-end;
};

\--layout-center-aligned: {  /* IE10 */
    -ms-align-content: center;
    align-content: center;
};

\--layout-between-aligned: {  /* IE10 */
    -ms-align-content: space-between;
    align-content: space-between;
};

\--layout-around-aligned: {  /* IE10 */
    -ms-align-content: space-around;
    align-content: space-around;
};

/*******************************
            Other Layout
*******************************/

\--layout-block: {
    display: block;
};

\--layout-invisible: {
    visibility: hidden !important;
};

\--layout-relative: {
    position: relative;
};

\--layout-fit: {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
};

\--layout-scroll: {
    -webkit-overflow-scrolling: touch;
    overflow: auto;
};

\--layout-fullbleed: {
    margin: 0;
    height: 100vh;
};

/* fixed position */

\--layout-fixed-top: {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
};

\--layout-fixed-right: {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
};

\--layout-fixed-bottom: {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
};

\--layout-fixed-left: {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
};

}

.layout.horizontal,
.layout.vertical {
display: flex;
}

.layout.inline {
display: inline-flex;
}

.layout.horizontal {
flex-direction: row;
}

.layout.vertical {
flex-direction: column;
}

.layout.wrap {
flex-wrap: wrap;
}

.layout.no-wrap {
flex-wrap: nowrap;
}

.layout.center,
.layout.center-center {
align-items: center;
}

.layout.center-justified,
.layout.center-center {
justify-content: center;
}

.flex {
flex: 1 1;
flex-basis: 0.000000001px;
}

.flex-auto {
flex: 1 1 auto;
}

.flex-none {
flex: none;
}

    /**
* Alignment in cross axis.
*/
.layout.start {
align-items: flex-start;
}

.layout.center,
.layout.center-center {
align-items: center;
}

.layout.end {
align-items: flex-end;
}

.layout.baseline {
align-items: baseline;
}

/**
* Alignment in main axis.
*/
.layout.start-justified {
justify-content: flex-start;
}

.layout.center-justified,
.layout.center-center {
justify-content: center;
}

.layout.end-justified {
justify-content: flex-end;
}

.layout.around-justified {
justify-content: space-around;
}

.layout.justified {
justify-content: space-between;
}

/**
* Self alignment.
*/
.self-start {
-ms-align-self: flex-start;
align-self: flex-start;
}

.self-center {
-ms-align-self: center;
align-self: center;
}

.self-end {
-ms-align-self: flex-end;
align-self: flex-end;
}

.self-stretch {
-ms-align-self: stretch;
align-self: stretch;
}

.self-baseline {
-ms-align-self: baseline;
align-self: baseline;
}

/**
* multi-line alignment in main axis.
*/
.layout.start-aligned {  /* IE10 */
-ms-align-content: flex-start;
align-content: flex-start;
}

.layout.end-aligned {  /* IE10 */
-ms-align-content: flex-end;
align-content: flex-end;
}

.layout.center-aligned {  /* IE10 */
-ms-align-content: center;
align-content: center;
}

.layout.between-aligned {  /* IE10 */
-ms-align-content: space-between;
align-content: space-between;
}

.layout.around-aligned {  /* IE10 */
-ms-align-content: space-around;
align-content: space-around;
}
.filter-icon-container {
    width: 48px;
    height: 48px;
}

 .filter-icon:hover {
    cursor: pointer;
}

.filter-container {
    /* height: 324px; */
    min-height: 380px;
    max-height: 500px;
}

.filter-body {
    background-color: rgba(0,0,0,.03);
}

 .filter-header {
    min-width: 340px;
    width: 100%;
    height: 48px;
    background-color: var(--app-user-repos-background-color);
    padding: 0 8px 0 16px;
    color: var(--secondary-text-color);
    font-weight: bold;
}

 .filter-menu-expansion-panel[expanded] .filter-down-arrow {
    display: none;
}

 .fitler-menu-detail {
    background-color: #eee;
}

 .filter-menu-title-container {
    font-weight: bold;
    color: rgba(0,0,0,0.87);
}

 .filter-menu-title {
    margin-left: 32px;
}

.rotate-90 {
    transform: rotate(90deg);
}

.chevron {
    width: 24px;
    height: 24px;
}


@media screen and (max-width: 768px) {
    .filter-header {
        min-width: unset;
    }

    .filter-popover {
        width: 100% !important;
        height: 100% !important;
        max-width: 100% !important;
        max-height: 100% !important;
        top: 0 !important;
        left: 0 !important;
        background-color: white !important;
    }

    .filter-container {
        min-height: 100%;
        max-height: 100%;
    }

}

.truncate {
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Card {
    /* margin-left: auto;
    margin-right: auto; */
    min-height: 64px;
    background-color: var(--workspace-column-card-color);
    margin-bottom: 4px;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.25);
    /* width: 220px; */
}
/* 
.Card {
    margin: 4px 0;
} */


.card-container {
    width: 280px;
    box-sizing: content-box;
    /* border: 1px solid black; */
    /* border-radius: 5px; */
    /* padding: 0 5px; */
}

.card-extra-details {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #e0f0f9;
}


.HeaderBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    padding: 6px 8px;
}

.Link {
    color: #29a9e2;
    font-size: 16px;
    font-weight: bold;
}

.RepoName {
    flex: 1 1;
    font-size: 16px;
    color: #6990a1;
    margin-left: 8px;
}



.AssigneeContainer {
    margin-left: 8px;
}

.Assignee:not(:first-child) {
    margin-left: 2px;
}

.Assignee {
    width: 16px;
    height: 16px;
    box-sizing: content-box;
    border-radius: 2px;
}

.OverflowAssignee {
    margin-left: 2px;
    width: 16px;
    height: 16px;
    font-size: 10px;
    text-align: center;
    line-height: 16px;
    border-radius: 2px;
    background-color: #d8d8d8;
}

.CardBody {
    padding: 4px 8px 12px;
    word-break: break-word;
    color: var(--card-body-color);
    font-size: 16px;
    font-weight: 500;
}

.Footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 10px;
    margin-right: 8px;
    /* margin-bottom: 4px; */
}

.spacer-4 {
    width: 4px;
}

.milestone-details {
    color: #6990a1;
    font-size: 16px;
    margin-left: 10px;
    padding-bottom: 8px;
}

.milestone-details a {
    color: inherit !important;
}

.LabelCard:last-child {
    margin-bottom: 8px;
}


.LabelText {
    margin: 0;
    line-height: 20px;
    font-size: 12px;
    /* display: none; */
}

.LabelCard {
    padding: 0 8px;
    display: block;
    border-radius: 2px;
    margin-right: 4px;
    margin-bottom: 4px;
    height: 20px;
    max-width: 100%;
    border-radius: 5px;
    /* transition: max-width 0.2s; */
    box-sizing: border-box;

}

@media screen and (max-width: 768px) {
    .Card {
        width: 100%;
    }

    .card-container {
        width: 100%;
    }

}


/* .Card:hover .LabelCard {
    max-width: 100%;
    border-radius: 5px;
    transition: max-width 0.2s;
} */

/* .Card:hover .LabelText {
    display: block;
} */


.app-workspace {
    background-color: var(--app-workspace-background-color);
    overflow: auto;
    /* height: calc(100% - 48px); */
    height: 100%;
}


.column-title {
    font-size: 16px;
    /* padding-left: 8px; */
    font-weight: bold;
    height: 48px;
}

.workspace-column {
    /* margin: 24px 16px; */
    padding: 0 16px 16px;
    padding-bottom: 0; 
    height: 100%;
    border-right: 0.5px solid rgba(0,0,0,0.16);
}

.left-dragzone {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100px;
    pointer-events: none;
}

.right-dragzone {
    position: absolute;
    right: 0;
    height: 100%;
    width: 100px;
    pointer-events: none;
}

.txt-rotate-90 {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
}


.vertical-text {
    -webkit-writing-mode: tb-rl;
            writing-mode: tb-rl;
}
.column-padding-override {
    padding: 0 8px 8px;
}


.title-bold-24 {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.2px;
    text-align: center;
}

.title-24 {
    font-size: 16px;
    letter-spacing: 0.3px;
    text-align: center;
}

.container-32 {
    height: 32px;
}

.font-size-12 {
    font-size: 12px;
}

@media screen and (max-width: 768px) {
    .Card {
        width: 100%;
    }
}

.fade-in {
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
    animation: fadein 2s;
}
    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
                /* Firefox < 16 */
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Internet Explorer */
/* Opera < 12.1 */

.right-panel {
    width: 588px;
    overflow-x: hidden;
}

#rightPanel:first-child {
    width: 588px;
    overflow-x: hidden;
}

/* 
div[role="presentation"] {
    width: 588px;
} */

/* div[role="document"] .app-nav-bar-2 {
    width: inherit;
    top: 48px;
    height: calc(100% - 48px);
    color: white;
} */

.panel-container {
    padding: 16px;
}

@media screen and (max-width: 768px) {
    .right-panel {
        width: 100%;
    }
    #rightPanel:first-child {
        width: 100%;
    }

    div[role="document"] {
        width: 100%;
    }
}


.github-html-container {
    font-size: 0.75em !important;
    /* padding: 16px; */
}

/* .github-html-container img {
    width: 400px;
    height: 400px;
    object-fit: contain;
} */
.app-logo-container {
    margin: 24px 96px 8px 96px;
    text-align: center;
}

.app-logo {
    width: 96px;
    height: 96px;
}

.app-title {
    color: rgba(0, 0, 0, 0.87);
    font-size: 24px;
    text-align: center;
}

.login-section-break {
    margin: 0 33px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.login-input-container {
    margin: 0 56px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.login-btn {
    max-width: unset;
}

.log-out {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
}

.logincontainer {
    width: 288px;
    min-height: 281px;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
    background-color: #ffffff;
}


/* @media (min-width: 717px) {
    .logincontainer {
        width: 288px;
        min-height: 281px;
        border-radius: 4px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
        background-color: #ffffff;
    }
}

@media (max-width: 717px) {
    .logincontainer {
        width: 100%;
        height: 100%;
    }
} */
