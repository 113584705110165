

.app-workspace {
    background-color: var(--app-workspace-background-color);
    overflow: auto;
    /* height: calc(100% - 48px); */
    height: 100%;
}


.column-title {
    font-size: 16px;
    /* padding-left: 8px; */
    font-weight: bold;
    height: 48px;
}

.workspace-column {
    /* margin: 24px 16px; */
    padding: 0 16px 16px;
    padding-bottom: 0; 
    height: 100%;
    border-right: 0.5px solid rgba(0,0,0,0.16);
}

.left-dragzone {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100px;
    pointer-events: none;
}

.right-dragzone {
    position: absolute;
    right: 0;
    height: 100%;
    width: 100px;
    pointer-events: none;
}

.txt-rotate-90 {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
}


.vertical-text {
    writing-mode: tb-rl;
}
.column-padding-override {
    padding: 0 8px 8px;
}


.title-bold-24 {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.2px;
    text-align: center;
}

.title-24 {
    font-size: 16px;
    letter-spacing: 0.3px;
    text-align: center;
}

.container-32 {
    height: 32px;
}

.font-size-12 {
    font-size: 12px;
}

@media screen and (max-width: 768px) {
    .Card {
        width: 100%;
    }
}
