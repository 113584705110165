.app-logo-container {
    margin: 24px 96px 8px 96px;
    text-align: center;
}

.app-logo {
    width: 96px;
    height: 96px;
}

.app-title {
    color: rgba(0, 0, 0, 0.87);
    font-size: 24px;
    text-align: center;
}

.login-section-break {
    margin: 0 33px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.login-input-container {
    margin: 0 56px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.login-btn {
    max-width: unset;
}

.log-out {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
}

.logincontainer {
    width: 288px;
    min-height: 281px;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
    background-color: #ffffff;
}


/* @media (min-width: 717px) {
    .logincontainer {
        width: 288px;
        min-height: 281px;
        border-radius: 4px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
        background-color: #ffffff;
    }
}

@media (max-width: 717px) {
    .logincontainer {
        width: 100%;
        height: 100%;
    }
} */